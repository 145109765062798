import React, { useState } from 'react';
import "./deleteDataCSS.scss";
import { awsConfig } from '../constants/defaultValues';

const DeleteUser = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const deleteUser = async () => {
        if (!email.trim()) {
            alert("กรุณากรอกอีเมลที่ต้องการลบ");
            return;
        }

        const isConfirmed = window.confirm(`คุณแน่ใจหรือไม่ที่ต้องการลบ ${email} ?`);
        if (!isConfirmed) return;

        setIsLoading(true);
        try {
            const response = await fetch(`${awsConfig.API.endpoints[0].endpoint}/deleteMember?email=${encodeURIComponent(email)}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            if (response.ok) {
                setMessage(`✅ ลบ ${email} สำเร็จ`);
                setEmail("");
            } else {
                setMessage('❌ ไม่สามารถลบได้: ' + response.statusText);
            }
        } catch (error) {
            setMessage('❌ เกิดข้อผิดพลาด: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='delete-user-container'>
            <h3>ลบผู้ใช้โดยใช้อีเมล</h3>
            {message && <p className='message-delete' style={{ color: 'red' }}>{message}</p>}
            <div className="input-group">
                <input
                    className="form-control"
                    type="email"
                    placeholder="กรอกอีเมล"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={isLoading}
                />
                <button
                    className="btn btn-danger"
                    onClick={deleteUser}
                    disabled={isLoading}
                >
                    {isLoading ? "⏳ กำลังลบ..." : "ลบผู้ใช้"}
                </button>
            </div>
        </div>
    );
};

export default DeleteUser;
