import React from 'react';
import GPSPermission from '../components/GPSPermission';

function TestGPS_Permission() {
  return (
    <div className="App">
      <h1>ทดสอบ GPS Permission V.6</h1>
      <GPSPermission />
    </div>
  );
}

export default TestGPS_Permission;
